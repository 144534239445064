@import "./../../mixins.scss";

.Bookmarks {
  ul.bookmarks-list {
    & > li {
      margin-bottom: 10px;

      .favicon {
        width: 48px;
        height: 48px;
        border-radius: 5px;
        margin-right: 14px;
        margin-top: 4px;
        float: left;
      }
    }
  }

  .dropdown-toggle::after {
    display: none !important; 
  }
}