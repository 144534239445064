@import "./../../mixins.scss";
@import "./../../variables.scss";

.Bookmark {
  .favicon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-top: 4px;
    float: left;
  }

  .rounded-20 {
    border-radius: 20px;
  }

  .comments-list {
    > li {
      width: auto;
      max-width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.popover-arrow {
  display: none !important;
  visibility: hidden !important;
}
