@import "./../../mixins.scss";

.Tags {
  .tags-sidebar-list {

    & > li {
      margin-bottom: 5px;
      font-size: 1.2rem;

      .badge {
        @include tag;

        &.active {
          background-color: #333;
          color: #fff;
        }

        &:hover {
          cursor: pointer;
          background-color: #e0e0e0;
          color: #333;
        }
      }
    }
  }
}