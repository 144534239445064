.comment {
  display: inline-block;
  margin-bottom: 15px;

  background-color: #efefef;
  padding: 10px;
  border-radius: 10px;

  &:hover {
    > .messageTimestamp {
      visibility: visible;
    }
  }

  > .messageTimestamp {
    font-size: .7rem;
    visibility: hidden;
  }
}