$size: 30px;

.Acronyms {
  .label {
    margin-top: 1px !important;
  }

  ul {
    & > li.Acronyms-acronym-item {
      background-color: #ccc;
      color: #333;
      height: $size;
      width: $size;
      line-height: $size;
      text-align: center;
      border-radius: 100px;
      display: inline-block;
      margin-right: 3px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}